/**
 * HEADER RESULTS PANEL
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-header-results-panel {
  padding: 0 0 30px; margin: 0; position: relative; display: flex; flex-direction: column; justify-content: space-between;

  .mobile-app & { padding-top: 40px; }
  .h2-style { font-size: 30px; font-weight: $orx-font-weight-light; width: 100%; }
  .sep { margin: 0 4px; }
  .link-style { color: $orx-gray-3c; font-weight: $orx-font-weight-bold; }
  .summary-result {
    background-color: $orx-gray-3c; color: white; font-size: 14px; line-height: 23px; font-weight: $orx-font-weight-light; padding: 15px;

    strong { font-size: 16px; font-weight: $orx-font-weight-bold; }
  }

}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */
@media #{$orx-mobile-first-md} {
  .cpt-header-results-panel { margin: 0 -15px; }
}

@media #{$orx-mobile-first-lg} {
  .cpt-header-results-panel { flex-direction: row; padding: 15px 0; margin: 0; }
}
