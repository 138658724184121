/**
 * BLOCK B2B
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-block-b2b {
  background-color: #3c3c3c; margin-top: 30px;; margin-left: 30px; padding: 15px;

  .title { color: white; font-size: 24px; font-weight: 700; margin-bottom: 20px; }

  .containers-b2b-wrap {
    display: flex; flex-direction: row; align-items: flex-end;

    .container-b2b {
      width: 25%;
      .label-b2b { color: white; margin-bottom: 5px; display: block; }
      .ipt-b2b { width: 220px; height: 36px; border-radius: 3px; border: 0 none; }
    }

    .button-wrap {
      width: 25%;
      .elem-button--default { background-color: #59a80f; padding: 9px 35px; width: 100%; }
    }

  }

}