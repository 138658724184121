/**
 * POPIN
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-orx-popin {
  @include position(fixed, 0 null null 0);
  @include orx-size(100%);
  z-index: 200;

  .overlay-popin {
    @include position(fixed, 0 null null 0);
    @include orx-size(100%);
    background-color: rgba(black, 0.8);
  }

  .btn-close-popin {
    @include orx-size(30px);
    @include position(absolute, 10px 10px null null);
    background-color: white; border-radius: 100%; display: flex; align-items: center; justify-content: center; z-index: 200;
  }

  // VIDEO
  &.popin-video-content {
    padding: 0 5%;

    .btn-close-popin { display: none; }
  }

  // MAP
  &.popin-map-content {
    .btn-close-popin { top: 56px + 10px; }
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {

  .cpt-orx-popin {
    // VIDEO
    &.popin-video-content { padding: inherit; }

    // MAP
    &.popin-map-content {
      .btn-close-popin { top: 88px + 10px; }
    }
  }

}

@media #{$orx-mobile-first-lg} {

  .cpt-orx-popin {
    @include position(absolute, 0 null null 0);

    // SHARE
    &.popin-share-content {
      .btn-close-popin { top: 25px; right: 110px; cursor: pointer; }
    }

    // PROMOTE
    &.popin-promote-content {
      .btn-close-popin { top: 8px; right: 76px; cursor: pointer; z-index: 3000; }
    }
  }

}
