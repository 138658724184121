/**
 * POPIN VIDEO
 *
 * @type element
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-popin-video {
  @include position(relative, 20% null null 50%);
  background-color: white; border-radius: 3px; padding: 30px; transform: translate(-50%); z-index: 200;

  .btn-close-popin-video {
    @include position(absolute, 0 0 null null);
    @include orx-size(30px);
    background-color: $orx-brand-primary; color: white; border-radius: 0; align-items: center; z-index: 201; cursor: pointer; display: flex; justify-content: center;
  }

  .player { width: 100%; height: 230px; }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .cpt-popin-video {
    width: 600px;

    .player { height: 355px; }
  }
}
@media #{$orx-mobile-first-lg} {
  .cpt-popin-video {
    width: 900px; position: fixed;

    .player { height: 515px; }
  }
}
