/**
 * DIAPORAMA PRODUCT
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-diaporama-product {
  position: relative;

  .product-style {
    height: 214px;

    .diaporama-item {
      .elem-image { height: 214px; }
    }
  }

  .thumbnails-style {
    height: 107px; margin-top: 4px;

    .thumbnails-item {
      padding: 0 2px; position: relative;

      &:after {
        @include position(absolute, 0 null null 0);
        @include orx-size(100%);
        background-color: rgba(white, 0.6); content: "";
      }
      .elem-image { height: 107px; }
    }

    .slick-current {
      .thumbnails-item {
        padding: 0; border: 2px solid $orx-brand-primary;

        &:after { display: none; }
        .elem-image { height: 103px; }
      }
    }
  }

  .diaporama-numbers {
    @include position(absolute, 5px 5px null null);
    background-color: rgba(black, 0.3); color: white; padding: 6px 8px; display: flex; align-items: center; justify-content: center; z-index: 99;

    .icon-wrap { margin-right: 7px; }
    .numbers { font-weight: $orx-font-weight-bold; }
  }

  .video-wrap {
    @include position(absolute, null null 0 0);
    background-color: rgba(0,0,0,0.7); width: 100%; height: 29px;

    .video-button {
      color: white; font-size: 16px; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;
      .elem-ff-icon { font-size: 23px; margin-right: 5px; }
    }
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .cpt-diaporama-product {
    .product-style {
      height: 423px; position: relative;

      .diaporama-item {
        .elem-image { height: 423px; }
      }
    }
    .video-wrap {
      height: 47px; bottom: 110px;

      .video-button {
        font-size: 22px;
        .elem-ff-icon { font-size: 30px; color: $orx-white; margin-right: 5px; }
      }
    }
  }
}