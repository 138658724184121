/**
 * LOGO PARTNER
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-logo-free-cancel {
  @include position(absolute, 0 0 null null);
  z-index: 1;

  .elem-image {
    width: 183px; height: 33px !important; display: flex;

    img {
      @include orx-size(100%,auto);
      margin: auto;
    }
  }
}


/** ----------------------------------------------------------------
* MEDIA QUERY
*/

@media #{$orx-mobile-first-md} {
  .cpt-logo-free-cancel {
    @include position(absolute, -1px null null 0);

    .elem-image { width: 223px; height: 52px !important; }
  }
}