/**
 * DIAPORAMA
 *
 * $type component
 */


/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-diaporama {
  width: 100%; position: relative;

  // IMAGE
  .elem-image { width: 100%; position: relative; }

  // ARROWS
  .arrow-square {
    @include orx-size(27px,53px);
    @include position(absolute, 50% null null null);
    background: rgba(black,0.3); font-size: 21px; color: $orx-white; cursor: pointer; border-radius: 0; z-index: 80; position: absolute; top: 50%;
    transform: translateY(-50%);

    .elem-ff-icon {
      @include position(absolute, 50% 2px null null);
      font-weight: bold !important; font-family: $orx-font-family-icon;
      transform: translateY(-50%);
    }
  }

  .arrow-square-slick-prev {
    @include position(absolute, null null null 0);

    .elem-ff-icon {
      @include position(absolute, 50% auto null 2px);
    }
  }
  .arrow-square-slick-next {
    @include position(absolute, null 0 null null);

  }

  // OVERRIDE SLICK
  .slick-dots {
    bottom: inherit;

    li {
      width: 16px; height: 16px; margin: 0 5px; padding: 0; cursor: pointer; position: relative; display: inline-block;

      button {
        font-size: 0; text-align: center; line-height: 0; display: block; width: 16px; height: 20px; padding: 5px; cursor: pointer; color: transparent; border: 0; outline: none; background: transparent;

        &:before { background-color: #c1c1c1; width: 13px; height: 13px; border-radius: 13px; box-shadow: inset 1px 1px 0 1px rgba(0,0,0,0.2); position: absolute; top: 5px; left: 0; content: ''; opacity: 1; }
      }

      &.slick-active button:before { background-color: $orx-brand-primary; }
    }

  }

}
