/**
 * SLIDER RANGE
 *
 * $type components
 */


/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-slider-range {
  margin-right: 5px; position: relative;

  .rc-slider { margin: 10px 0; }
  .rc-slider-track { background-color: $orx-gray-e6; height: 6px; }
  .rc-slider-step { height: 6px; }
  .rc-slider-handle {
    @include orx-size(20px);
    background-color: $orx-brand-primary; border: 5px solid $orx-brand-primary; margin-top: -7px;

    &:hover,
    &:active,
    &:focus { border-color: $orx-brand-secondary; }
  }
  .slider-range-value {
    strong { color: $orx-brand-secondary; }
  }
  .rc-slider-rail { background-color: $orx-gray-e6; top: 6px; }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */



