/**
 * REFERENCE INFORMATIONS
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-reference-informations {
  background-color: white; border-bottom: 15px solid $orx-gray-80; padding: 15px 10px; display: flex; position: relative;

  .number-tel {
    width: 215px; display: flex;

    .icon-wrap {
      @include position(relative, 9px null null null);
      font-size: 30px; color: $orx-brand-primary; margin-right: 10px; position: relative;
    }
    .information-wrap {
      font-size: 13px;

      .phone { font-size: 16px; font-weight: $orx-font-weight-bold; margin-top: 3px; display: block; }
      .legend { font-size: 11px; font-weight: $orx-font-weight-light; margin-top: 2px; display: block; }
    }
  }
  .references {
    /*width: 40%;*/ color: $orx-gray-a5; font-size: 18px; font-weight: $orx-font-weight-bold; display: flex; align-items: center; justify-content: center; position: relative;

    &:before {
      @include position(absolute, 0 null null -10px);
      @include orx-size(2px,100%);
      background-color: $orx-gray-e9; content: "";
    }
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-lg} {
  .cpt-reference-informations {
    background-color: $orx-gray-e9; border-color: $orx-gray-c9;

    .references {
      &:before { background-color: $orx-gray-c9; }
    }
  }
}
