/* DayPicker styles */

.DayPicker { display: block; font-size: 14px; }
.DayPicker-wrapper { position: relative; flex-direction: row; padding-bottom: 1em; user-select: none; }
.DayPicker-Months { display: flex; flex-wrap: wrap; justify-content: center; min-width: inherit; }
.DayPicker-Month {
  display: table; margin: 0 1em; margin-top: 1em; border-spacing: 0; border-collapse: collapse; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;

  &:first-child {
    .DayPicker-Caption {
      position: relative;

      &:after {
        @include position(absolute, 0 0 null null);
        @include orx-size(1px,33px);
        content: ""; background-color: transparent; z-index: 9;
      }
    }
  }
}
.DayPicker-NavButton { position: absolute; top: 19px; z-index: 88; display: inline-block; margin-top: 2px; width: 1.25em; height: 1.25em; background-position: center; background-size: 50%; background-repeat: no-repeat; color: #8B9898; cursor: pointer; }
.DayPicker-NavButton:hover { opacity: 0.8; }
.DayPicker-NavButton--prev {
  margin-right: 1.5em; left: 0;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
}
.DayPicker-NavButton--next {
  right: 0;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
}
.DayPicker-NavButton--interactionDisabled { display: none; }
.DayPicker-Caption {
  position: relative; display: table-caption; margin: 0 -16px 0.5em; padding: 8px 0.5em; text-align: left; color: #565656;

  .all-dates-month {
    font-size: 12px; font-weight: 400; background-color: $orx-white; margin: 7px -7px -8px; padding: 9px 0;

    .cbx-all-month { margin-right: 5px; position: relative; top: 2px; }
  }
}
.DayPicker-Caption > div { font-weight: bold; text-align: center; }
.DayPicker-Weekdays { display: table-header-group; margin-top: 1em; }
.DayPicker-WeekdaysRow { display: table-row; }
.DayPicker-Weekday { display: table-cell; padding: 0.5em; color: #565656; text-align: center; font-size: 0.875em; font-weight: 600; }
.DayPicker-Weekday abbr[title] { border-bottom: none; text-decoration: none; }
.DayPicker-Body { display: table-row-group; }
.DayPicker-Week { display: table-row; }
.DayPicker-Day {
  display: table-cell; padding: 0.5em; border-radius: 0; vertical-align: middle; text-align: center; cursor: pointer; color: #909090;

  &.selected { background-color: $orx-brand-primary; color: $orx-white; }
}
.DayPicker-WeekNumber { display: table-cell; padding: 0.5em; min-width: 1em; border-right: 1px solid #EAECEC; color: #8B9898; vertical-align: middle; text-align: right; font-size: 0.75em; cursor: pointer; }
.DayPicker--interactionDisabled .DayPicker-Day { cursor: default; }
.DayPicker-Footer { padding-top: 0.5em; }
.DayPicker:not(.DayPicker--interactionDisabled),
.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover { background-color: $orx-white !important; }



.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4A90E2;
  font-size: 0.875em;
  cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
  font-weight: bold; border-radius: 0;
}

.DayPicker-Day--included {
  background-color: #658697; color: $orx-white;
}

.DayPicker-Day--outside {
  color: #8B9898;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: #DCE0E0;
  font-weight: 300;
  cursor: default;
  /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #F7F8F8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #DCE0E0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  background-color: $orx-brand-primary;
  color: $orx-white;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51A0FA;
}

.DayPicker:not(.DayPicker--interactionDisabled),
.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #F0F8FF;
}

/* DayPickerInput */

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 1;

  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .DayPicker { display: inline-block; }
  .DayPicker-Months { min-width: 509px; }
  .DayPicker-Month {
    &:first-child {
      .DayPicker-Caption {
        &:after { background-color: $orx-brand-primary; }
      }
    }
  }
}

@media #{$orx-mobile-first-lg} {

}

@media #{$orx-mobile-first-xl} {

}
