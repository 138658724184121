/**
 * RESULTS PANEL
 *
 * $type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.unbeatableOffer-container {
  @include position(fixed, null null 0 0);
  width: 100%;
  z-index: 1; background-color: $orx-white; box-shadow: 0 -5px 15px #383838;
  .header {
    height: 30px; background-color: $orx-border-primary-color; color: $orx-white; padding: 10px; display: flex; align-items: center; justify-content: space-between; font-size: 12px;
    #close-pop-in { width: 20px; height: 20px; display: flex; justify-content: center; align-items: center; }
  }
  a { color: inherit; }
  .content-container {
    background-color: $orx-white; padding: 10px; width: 100%;
    .label {
      margin-bottom: 8px;
      .destination-label {
        display: flex; align-items: center; font-size: 11px; margin-bottom: 3px;
        .destination-city:before { content: '-';  font-size: 11px; margin: 0 5px; }
      }
      .unbeatableProduct-title { font-size: 12px; font-weight: bold; }
    }
    .content {
      width: 100%; display: flex;
      .unbeatableProduct-image {
        @include orx-size(185px, 103px);
      }
      .unbeatableProduct-infos {
        padding: 10px 0 0 10px; display: flex; justify-content: space-between; width: calc(100% - 185px);
        .optional-infos {
          @include orx-size(95px, 80px);
          display: flex; flex-direction: column; justify-content: space-between; position: relative;
          .unbeatableProduct-reductionRate {
            @include orx-size(50px, 25px);
            display: flex; justify-content: center; align-items: center; border: 2px solid $orx-border-primary-color; color: $orx-border-primary-color;
            .mobile-wording {}
            .desktop-wording { display: none; }
          }
          .tripadvisor-informations {
            @include position(absolute, 40px null null null);
            font-size: 11px; width: 85px;
            .views { white-space: nowrap; }
            .elem-image { left: -5px; }
          }
        }
        .price-block {
          height: fit-content;
          .price {
            display: flex; justify-content: flex-end;
            .unbeatableProduct-price { font-size: 18px; color: $orx-border-primary-color; font-weight: bold; }
            .mention {
              @include position(relative, 2px null null null);
              font-size: 10px; line-height: 9px; margin-left: 5px; width: min-content;
            }
          }
          .brochure-price { font-size: 10px; line-height: 9px; white-space: nowrap; text-align: right; margin-top: 3px; }
        }

      }
    }
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .unbeatableOffer-container {
    left: 25%; width: 470px;
    .label { display: flex; justify-content: space-between; flex-direction: row-reverse; }
    .unbeatableProduct-reductionRate {
      width: 140px !important; height: 28px !important;
      .mobile-wording { display: none !important; }
      .desktop-wording { display: block !important; }
    }
  }
}

@media #{$orx-mobile-first-lg} {
  .unbeatableOffer-container { left: 40%; }
}
