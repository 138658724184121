/**
 * BOOKING PRODUCT
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-booking-product {
  position: relative;

  .title { font-size: 30px; font-weight: $orx-font-weight-light; text-align: center; margin: 0 15px; padding: 20px 0 15px; border-bottom: 1px solid $orx-gray-e2; }
  .booking-overlay {
    @include position(fixed, 0 null null 0);
    @include orx-size(100%);
    background-color: rgba(black, 0.8); z-index: 100;
  }
  .calendar-error-message {
    @include position(relative, -15px null null null);
    color: #c3a13c; padding: 5px; background-color: #fff7df; height: 40px; display: flex; align-items: center; font-size: 13px;
  }
  .booking-content {
    margin: 15px;

    .booking-item {
      margin-bottom: 20px;

      &.error-style {
        .label { font-weight: $orx-font-weight-bold; color: red; }
        .elem-custom-select {
          border: 1px solid red;

          .custom-select-input-button { color: red; }
          .custom-select-input-txt { color: red; }
        }
        .error-message { font-weight: $orx-font-weight-bold; color: red; margin: 10px 0 0; display: block; }
      }
      .error-message { display: none; }
    }
    .label { font-size: 12px; text-transform: uppercase; margin-bottom: 6px; }
    .special-offer {margin-bottom: 20px;}
  }
  .bp-booking-price {
    background-color: $orx-gray-e9;

    .booking-price-content {
      padding: 20px; display: flex; flex-direction: column; align-items: center; justify-content: space-around; position: relative;

      .price-block {
        display: flex; position: relative;

        .price {
          font-size: 40px; font-weight: $orx-font-weight-bold; color: $orx-brand-primary;

          span { font-weight: $orx-font-weight-light; margin-left: -6px; display: inline-block; }
        }
        .by {
          @include position(relative, 8px null null null);
          font-size: 14px; line-height: 15px; margin-left: 7px;
        }
      }
      .brochure-price { margin-bottom: 20px; }
      [class*=elem-button--].disabled {background-color: #504e50; opacity: .5; pointer-events: none;}
      .button-wrap {
        width: 100%; position: relative;

        .elem-button--default {
          width: 100%; font-size: 18px;

          .elem-ff-icon { font-size: 14px; margin-left: 9px; top: 1px; }
        }

        &.disabled {
          &:after {
            @include position(absolute, 0 null null 0);
            @include orx-size(100%);
            content: ""; background-color: rgba(255, 255, 255, 0); z-index: 2;
          }
          .elem-button--default { color: rgba(255, 255, 255, 0.2); }
        }

        &.active-loader {
          .elem-button--default { color: $orx-brand-primary; }
          .loader-content {
            @include position(absolute, 0 null null 0);
            @include orx-size(100%);
            background-color: transparent; border-radius: 0; text-align: center; z-index: 1001;

            .lds-ellipsis {
              @include orx-size(80px,48px);
              margin-top: 0; display: inline-block; position: relative;

              div {
                @include position(absolute, 17px null null auto);
                @include orx-size(13px);
                border-radius: 50%; background: white;
                animation-timing-function: cubic-bezier(0, 1, 1, 0);

                &:nth-child(1) {
                  left: 8px;
                  animation: lds-ellipsis1 0.6s infinite;
                }
                &:nth-child(2) {
                  left: 8px;
                  animation: lds-ellipsis2 0.6s infinite;
                }
                &:nth-child(3) {
                  left: 32px;
                  animation: lds-ellipsis2 0.6s infinite;
                }
                &:nth-child(4) {
                  left: 56px;
                  animation: lds-ellipsis3 0.6s infinite;
                }
              }
            }
          }

          @keyframes lds-ellipsis1 {
            0% {
              transform: scale(0);
            }
            100% {
              transform: scale(1);
            }
          }
          @keyframes lds-ellipsis3 {
            0% {
              transform: scale(1);
            }
            100% {
              transform: scale(0);
            }
          }
          @keyframes lds-ellipsis2 {
            0% {
              transform: translate(0, 0);
            }
            100% {
              transform: translate(24px, 0);
            }
          }
        }
      }
    }
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-lg} {
  .cpt-booking-product {
    background-color: $orx-wrapper-full-gray; margin-bottom: 30px;

    .booking-content {
      .booking-item {
        &.active {
          position: relative; z-index: 2000;

          .label { color: white; }
          .elem-custom-select { border: 1px solid $orx-brand-primary; }
        }
      }
    }
  }
}
