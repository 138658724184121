/**
 * POPIN PROMOTE
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-popin-promote {
  @include position(absolute, 60px null null 50%);
  @include orx-size(90%, auto);
  background-color: white; border-radius: 3px; padding: 15px; z-index: 200;
  transform: translateX(-50%);

  .title {
    font-size: 20px; line-height: 25px; font-weight: $orx-font-weight-light; text-transform: none; text-align: center; padding: 0 0 15px; margin: 0 0 5px; border-bottom: 1px solid $orx-gray-e9;

    strong { font-weight: $orx-font-weight-bold; color: $orx-brand-primary; }
  }
  .txt { text-align: center; line-height: 20px; color: $orx-gray-80; }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-lg} {
  .cpt-popin-promote {
    @include position(absolute, 54px 72px null auto);
    @include orx-size(420px,auto);
    transform: translateX(0);
  }
}
