/**
 * BOOKING STICKY PRICE
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-booking-sticky-price {
  @include position(fixed, null null 0 0);
  @include orx-size(100%,auto);
  background-color: $orx-gray-e9; padding: 10px; border-top: 2px solid $orx-gray-c4; box-shadow: 0 -2px 12px rgba(black, 0.24); z-index: 99;

  .label { font-size: 12px; margin-bottom: 3px; }
  .d-flex { align-items: center; }
  .next-departure-block {
    width: 45%;

    .departure-city,
    .departure-date { font-weight: $orx-font-weight-bold; color: $orx-brand-primary; }
  }

  .price {
    display: flex; align-items: center; justify-content: flex-start;

    .real-price {
      color: $orx-brand-primary; font-size: 33px; font-weight: $orx-font-weight-bold;

      .devise { font-weight: $orx-font-weight-light; }
    }
    .by {
      font-size: 12px; line-height: 14px; margin-left: 4px;

      span { display: block; }
    }
  }

  .brochure-price { font-size:12px; line-height:14px; }

  .bsp-price-block {
    width: 55%;

    .button-wrap {
      position: relative;

      .elem-button--default {
        width: 100%; white-space: nowrap; padding: 12px 7px 12px 10px; font-size: 12px;

        .elem-ff-icon { font-size: 16px; margin-left: 5px; }
      }
    }
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-lg} {
  .cpt-booking-sticky-price {
    @include position(relative, null null null null);
    background-color: white; border-top: 0 none; box-shadow: 0 0 0 rgba(white, 0); margin-top: 2px; z-index: 99; display: block !important;

    .bsp-price-block {
      .button-wrap {
        .elem-button--default { font-size: 14px; }
      }
    }
  }
}
