/**
 * SORT PANEL
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-sort-panel {
  @include position(fixed, 55px null null 0);
  @include orx-size(100%,calc(100% - 40px));
  background-color: white; margin: 40px 0 0; padding: 20px 20px 70px; overflow-y: auto; z-index: 100;

  .mobile-app & { top: 0; }
  .h3-style { font-size: 24px; font-weight: $orx-font-weight-regular; margin: 0 0 10px; text-align: center; text-transform: none; }

  .sort-container {
    .sort-item {
      font-size: 16px; font-weight: 700; cursor: pointer; padding: 20px  20px 20px 10px; border-bottom: 1px solid #f2f3f3;
      a { color: #3c3c3c; text-decoration: none; }
     }
  }
}
