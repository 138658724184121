/**
 * SERP
 *
 * @type pages
 */

// INDEX
@import "../../_core/styles/index";

// PLUGINS
@import "../../../../../../node_modules/slick-carousel/slick/slick.scss";
@import "../../../../../../node_modules/slick-carousel/slick/slick-theme.scss";
@import "../../../../../../node_modules/rc-slider/assets/index";
// WRAPPERS
@import "../../wrappers/default/default";

// ELEMENTS
@import "../../elements/button/button";
@import "../../elements/icon/icon";
@import "../../elements/image/image";
@import "../../elements/title/title";
@import "../../elements/price/price";
@import "../../elements/customSelect/customSelect";
@import "../../elements/orx-elem/arrow/arrow";
@import "../../elements/orx-elem/checkboxes/checkboxes";
@import "../../elements/loaderFull/loaderFull";

// WIDGETS
@import "../../components/orxapiwidgetanimation/widget/accordion/accordion";

// COMPONENTS
@import "../../components/header/header";
@import "../../components/breadcrumb/breadcrumb";
@import "../../components/headerNavBar/headerNavBar";
@import "../../components/resultsPanel/resultsPanel";
@import "../../components/unbeatableOffer/unbeatableOffer";
@import "../../components/headerResultsPanel/headerResultsPanel";
@import "../../components/resultProduct/resultProduct";
@import "../../components/logoFreeCancel/logoFreeCancel";
@import "../../components/resultsSort/resultsSort";
@import "../../components/searchPanel/searchPanel";
@import "../../components/searchEngine/searchEngine";
@import "../../components/filterPanel/filterPanel";
@import "../../components/sortPanel/sortPanel";
@import "../../components/sliderRange/sliderRange";
@import "../../components/diaporama/diaporama";
@import "../../components/diaporamaProduct/diaporamaProduct";
@import "../../components/bookingProduct/bookingProduct";
@import "../../components/informationsProduct/informationsProduct";
@import "../../components/productExtra/productExtra";
@import "../../components/promotePdt/promotePdt";
@import "../../components/tabsProduct/tabsProduct";
@import "../../components/referenceInformations/referenceInformations";
@import "../../components/bookingCalendar/bookingCalendar";
@import "../../components/bookingTravelers/bookingTravelers";
@import "../../components/bookingStickyPrice/bookingStickyPrice";
@import "../../components/popin/popin";
@import "../../components/popinShare/popinShare";
@import "../../components/popinPromote/popinPromote";
@import "../../components/popinVideo/popinVideo";
@import "../../components/productInformationPrice/productInformationPrice";
@import "../../components/includePrice/includePrice";
@import "../../components/blockB2b/blockB2b";
@import "../../components/destinationInfo/destinationInfo";
@import "../../components/googlePanel/googlePanel";
@import "../../components/footer/footer";
@import "../../components/esosphera/esosphera";
@import "../../components/oneyBlock/oneyBlock";
@import "../../components/trustYou/trustYou";
@import "../../components/banner/banner";
