/**
 * FILTER PANEL
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-filter-panel {
  @include position(fixed, 55px null null 0);
  @include orx-size(100%,calc(100% - 40px));
  background-color: white; margin: 40px 0 0; padding: 20px 20px 140px; overflow-y: auto; z-index: 100;

  .mobile-app & { top: 0; }
  .h3-style { font-size: 24px; font-weight: $orx-font-weight-regular; margin: 0 0 10px; text-align: center; text-transform: none; }
  .link-wrap {
    text-align: center; text-decoration: underline; cursor: pointer;
  }
  .filter-panel-content {
    padding-bottom: 15px;

    .filter-panel-item {
      margin-top: 15px;

      .checkboxes-wrap { margin-bottom: 3px;
      .space-2 {margin-left: 10px;}
      .space-3 {margin-left: 20px;}
      }
    }
    .panel-item-title { font-size: 16px; font-weight: $orx-font-weight-bold; padding: 10px 0; margin: 0; }
    .link-wrap { text-align: left; margin-top: 15px; cursor: pointer; }


    .button-wrap {
      @include position(fixed, null null 0 0);
      width: 100%; background-color: $orx-gray-31; padding: 15px 20px; margin: 0;

      .elem-button-primary {
        @include orx-size(100%,40px);
        background-color: $orx-brand-primary; font-size: 16px; font-weight: 600; color: #fff; padding: 14px 35px; border-radius: 3px;display: flex; justify-content: center; align-items: center; border: 0 none;

        &.erase { background-color: $orx-gray-3c; }
      }
    }

  }

}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */
@media #{$orx-mobile-first-lg} {
  .cpt-filter-panel {
    @include position(relative, auto null null auto);
    @include orx-size(auto);
    margin: 0; padding: 20px; z-index: auto;

    .filter-panel-content { padding-bottom: 0; }
    .button-wrap { display: none; }
  }
}