/**
 * RESULTS PANEL
 *
 * $type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-results-panel {
  margin-top: 95px; padding-left: 0; position: relative;

  .mobile-app & { margin-top: 0; }

  .no-results {
    border: 1px solid $orx-brand-primary; margin: 10px 0; padding: 10px; display: flex; flex-direction: column;

    .no-results-icon { display: none; }

    .no-results-msg {
      display: flex; flex-direction: column;

      .text-first-part { font-weight: $orx-font-weight-black; font-size: 16px; color: $orx-brand-primary; margin-bottom: 5px; }
      .text-second-part { font-weight: $orx-font-weight-light; font-size: 14px; color: $orx-gray-80; }
    }

    .no-results-btn {
      .button-wrap {
        text-align: right; margin-block-start: -12px;
        .elem-button--default { font-size: 12px; padding: 10px; }
      }
    }
  }

  .primary { color: $orx-brand-primary }

  .results-panel-content {
    padding: 0 15px;

    .button-wrap { text-align: center; }

    .cpt-oney-block { margin: -13px 0 15px; }
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .cpt-results-panel {

    .no-results {
      padding: 0 10px; flex-direction: row; justify-content: space-between; align-items: center;

      .no-results-icon {
        @include orx-size(70px,auto);
        display: block;
      }

      .no-results-msg {
        .text-first-part { font-size: 20px; margin-bottom: inherit; }
        .text-second-part { font-size: 20px; }
      }

      .no-results-btn {
        .button-wrap {
          text-align: inherit; margin-block-start: inherit;
          .elem-button--default { font-size: 14px; padding: 10px 15px; }
        }
      }
    }
  }
}

@media #{$orx-mobile-first-lg} {
  .cpt-results-panel {
    margin-top: inherit; padding-left: 30px;

    .no-results {
      margin-block-start: 30px; margin-block-end: -10px;

      .no-results-icon { margin-left: 30px; }

      .no-results-msg {
        .text-first-part { font-size: 24px; }
        .text-second-part { font-size: 22px; }
      }
    }

    .results-panel-content {
      padding: 0;

      .cpt-oney-block { margin: -10px 0 16px; }
    }
  }
}