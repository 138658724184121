/**
 * TABS PRODUCT
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-tabs-product {
  margin-block-end: 30px; position: relative;

  .tabs {
    padding: 10px 0; margin: 0; display: none; align-items: center; justify-content: center;

    .tab {
      background-color: $orx-gray-e9; color: rgba($orx-black, 0.27); inline-size: 100%; padding: 10px 15px; text-align: center; text-transform: uppercase; font-weight: bold; display: block; cursor: pointer;

      &.selected { background-color: $orx-brand-primary; color: $orx-white; }
    }
  }

  .product-tabs-content {
    .tab-content {
      display: block;

      &.selected { display: block; }

      .added-values {
        inline-size: 100%; background-image: $orx-linear-gradient-flash-sales; border-radius: 7px; padding: 10px 3px 3px; margin-block-end: 10px; overflow: hidden; position: relative;

        &:before {
          @include position(absolute, 0 null null 0);
          @include orx-size(100%, 50px);
          background-image: url("../../_core/css_imgs/deal-pattern.svg"); opacity: 0.1; border-radius: 7px; z-index: 0; content: "";
        }
        .added-values-title { color: $orx-white; font-size: 16px; margin-inline-start: 10px; position: relative; z-index: 1; }

        .flash-sale-image { color: $orx-white; font-size: 16px; margin-inline-start: 7px; position: relative; z-index: 1; }

        .added-values-container {
          inline-size: 100%; background-color: $orx-white; border-radius: 7px; padding: 5px; margin-block-start: 10px;

          .added-values-item {
            .added-values-image { color: $orx-brand-primary; }
            .added-values-text { margin-inline-start: 10px; }
          }
        }
      }

      .title-tab { display: block; }
      .hotel-name { font-weight: $orx-font-weight-light; font-size: 20px; margin-block-end: 20px; }
      .big-title { background-color: $orx-wrapper-full-gray; font-size: 18px; font-weight: $orx-font-weight-bold; padding: 10px 20px; border-radius: 25px; margin-block-end: 15px; text-transform: none;}

      .subtitle { color: $orx-brand-primary; text-transform: uppercase; font-size: 16px; font-weight: $orx-font-weight-semi-bold; margin: 25px 0 5px; }

      .title { font-weight: $orx-font-weight-bold; text-transform: uppercase; }

      .list-style {
        list-style: none; font-size: 14px; line-height: 21px; margin: 0; padding: 0;

        .icon-wrap {
          display: inline;

          .icon { max-inline-size: 30px; min-inline-size: 30px }
        }

        .list-style-item { color: $orx-gray-80; position: relative; }
        
        &.service-picto {
          padding: 0 20px; display: flex; flex-wrap: wrap;

         .list-style-item {
            inline-size: 50%; margin-block-end: 8px; display: flex; align-items: center;

           .details-label { font-size: 12px; }
         }

         .icon-wrap { margin-inline-end: 8px; }
        }
      }

      .txt { line-height: 20px; }
      .edito { padding: 0 20px; }
    }
    .title-tab { display: block; }
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {

  .cpt-tabs-product {
    .tabs {
      display: flex; justify-content: flex-start;

      .tab {
        @include orx-size(auto, 52px);
        border-radius: 6px; margin-right: 10px; text-align: center; align-items: center; justify-content: center; display: inline-flex;

        .elem-orx-arrow { display: none; }
      }
    }

    .product-tabs-content {
      .tab-content {
        display: none; padding-inline-end: 20px; margin-block-start: 15px;

        &.selected { display: block; }
        .list-style {
          &.service-picto {
            padding: 0 20px; display: flex; flex-wrap: wrap;

            .list-style-item {
              inline-size: 30%; margin-block-end: 8px; display: flex; align-items: center;

              .details-label { font-size: 14px; }
            }

            .icon-wrap { margin-inline-end: 8px; }
          }
        }

        .title-tab { display: none; }
      }
    }
  }

}