/**
 * POPIN SHARE
 *
 * @type element
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-popin-share {
  @include position(absolute, 60px null null 50%);
  @include orx-size(90%, auto);
  background-color: white; border-radius: 3px; padding: 15px; z-index: 200;
  transform: translateX(-50%);

  .title { font-size: 20px; font-weight: $orx-font-weight-light; text-transform: none; text-align: center; margin: 0 0 10px; }
  .icon-content {
    display: flex; align-items: center; justify-content: center;

    .icon-wrap {
      font-size: 36px;

      &.facebook { margin-right: 15px; color: #576ea5; }
      &.twitter { color: #55acee; }
    }
  }
  .add-input {
    margin-bottom: 10px; display: inline-flex; align-items: center; cursor: pointer;

    .add-more {
      @include orx-size(20px);
      border: 1px solid $orx-gray-df; border-radius: 100%; margin-right: 7px; display: flex; justify-content: center; align-items: center;
    }
    .label { font-weight: $orx-font-weight-bold; }
  }
  .form-label { font-weight: $orx-font-weight-bold; font-size: 16px; text-align: center; margin: 10px 0; }
  .input-wrap {
    margin-bottom: 15px; position: relative;

    input,
    select {
      @include orx-size(100%, 40px);
      padding: 0 10px; border: 1px solid $orx-gray-df;
    }
    textarea {
      @include orx-size(100%, 120px);
      border: 1px solid $orx-gray-df; padding: 10px;
    }
  }
  .message-error{display: none; font-size: 12px; font-weight: bold; line-height: 1.2;padding: 5px 10px;text-align: center;
    color: $orx-brand-primary; background-color: rgba(236,0,140,0.1); border: 1px solid $orx-brand-primary; }
  .btn-wrap { text-align: center; margin-top: 20px; }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-lg} {
  .cpt-popin-share {
    @include position(absolute, 75px 0 null auto);
    width: 420px;
    transform: translateX(0);
  }
}
