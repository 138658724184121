/**
 * PRODUCT EXTRA
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-product-extra {
   display: flex; justify-content: space-between; align-items: center; min-inline-size: 180px; margin: 0 auto; padding: 0 0 10px;

  // Flash Sales
  .countdown {
    display: flex; background-color: $orx-gray-3; color: $orx-white; block-size: 34px; border-radius: 9px 7px 7px 9px; margin-block-end: 3px;

    .cutting-zone {
      @include orx-size(48px, 34px);
      display: flex; align-items: center; background-color: $orx-brand-primary; font-size: 24px; padding-inline-start: 5px; border-radius: 7px 0 0 7px;

      &:after {
        @include orx-size(0);
        @include position(relative, null 1px null null);
        border-block-end: 34px solid $orx-gray-3; border-inline-end: 1px solid $orx-gray-3; border-inline-start: 19px solid transparent; content: "";
      }
    }
    .countdown-text {
      @include orx-size(73px, 34px);
      display: flex; justify-content: center; flex-direction: column; font-size: 12px; padding: 0 6px 4px 0;
    }
  }
}