/**
 * INFORMATIONS PRODUCT
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-informations-product {
  background-color: white; padding: 10px; position: relative;

  .geo-information {
    @include orx-size(200px, 55px);
    @include orx-border(transparent);
    background: url($orx-cssimgs-url + "map.png") no-repeat center; margin-block-end: 10px; display: flex; justify-content: center; align-items: center;

    &:hover { border-color: $orx-black; cursor: pointer; }

    .elem-ff-icon { margin: 0 4px 0 0; }
    .btn-geo-information { padding: 5px 10px; }
  }

  .description-pdt {
    margin-bottom: 15px;

    .title { font-size: 16px; font-weight: $orx-font-weight-bold; margin-bottom: 15px; }
    .list-style {
      margin-left: 20px; font-size: 14px; line-height: 21px;

      .list-style-item {
        color: $orx-gray-80; position: relative;

        &:before {
          @include position(absolute, -6px null null -12px);
          font-size: 24px; color: $orx-gray-80; display: block; content: ".";
        }
      }
    }
  }

  .details-pdt {
    text-align: center; margin: 15px 0 8px;

    .d-flex { align-items: flex-start; justify-content: space-evenly; }
    .sep { font-size: 34px; color: $orx-gray-e4; }
    .details-pdt-item {
      width: 100%; padding: 0 3px;

       .icon-wrap {
        color: $orx-brand-primary; font-size: 22px; margin-bottom: 5px;
        .elem-image { width: 22px; margin: 0 auto; }
      }
      .details-label { font-size: 12px; }
    }
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-lg} {
  .cpt-informations-product {
    margin-top: 2px;

    .description-pdt { margin: 20px 0; }
  }
}
