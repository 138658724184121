/**
 * PANEL GOOGLE
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */
.cpt-google-advertising-left {
  margin: 20px auto;
}
.cpt-google-advertising-bottom {
  width: 100%; margin: 20px auto;
  .google-ads-head {
    background-color: $orx-gray-e4; padding: 5px 10px;
    a {color: #4d4d4d; text-decoration: none;}
  }

}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .cpt-google-advertising-left {

  }
  .cpt-google-advertising-bottom {
    width: 1140px;
    position: relative;
    margin: 20px auto;
  }
}
