/**
 * BANNER
 *
 * $type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-banner-block {
  margin: -15px 15px 40px;

  .elem-image {
    @include orx-size(100%, 100px); overflow: hidden;

    img {
      @include orx-size(100%);
      vertical-align: top;object-fit: cover;display: block;
    }
  }

}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .cpt-banner-block {
    margin: -5px 15px 40px;

    .elem-image {
      @include orx-size(100%, 150px);
    }
  }
}

@media #{$orx-mobile-first-lg} {
  .cpt-banner-block {
    margin: -10px 0 30px;

    .elem-image {
      @include orx-size(100%, 150px);
    }
  }
}