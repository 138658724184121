/**
 * PRODUCT INFORMATION PRICE
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-product-information-price {

}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .cpt-product-information-price {
  }
}
