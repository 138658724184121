/**
 * RESULT PRODUCT
 *
 * $type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-oney-block {

  // SERP
  .page-serp & {
    @include orx-size(auto,55px);
    background-color: $orx-white; border: 1px solid $orx-oney-green; margin: -15px 15px 25px; padding-left: 15px; position: relative; display: flex; align-items: center;

    .oney-label {
      font-size: 20px; font-weight: $orx-font-weight-light; color: $orx-gray-80; margin-left: 5px; margin-top: -11px; white-space: nowrap;
      .oney-label-add { display: none; }
    }

    .oney-logo {
      @include orx-size(175px,100%);
      background: url($orx-cssimgs-url + "oney-serp-mobile.png") no-repeat center; background-size: 100%; margin-left: 8px; display: block;
    }

    .oney-infos {
      @include position(absolute, 50% 8px null null);
      @include orx-size(20px);
      @include orx-border($orx-oney-green);
      background-color: $orx-white; font-size: 12px; font-weight: $orx-font-weight-bold; color: $orx-oney-green; border-radius: 100%; display: flex; align-items: center; justify-content: center; transform: translateY(-50%);

      .link { font-size: 12px; color: $orx-oney-green; }
    }
  }

  // FICHE PRODUIT
  .page-package & {
     padding-block-start: 4px; display: flex; align-items: center; justify-content: center; position: relative;

    .oney-label {
      font-size: 14px; font-weight: $orx-font-weight-light; color: $orx-gray-80; margin-top: -5px; white-space: nowrap;
      .oney-label-add { display: none; }
    }

    .oney-logo {
      @include orx-size(94px,25px);
      background: url($orx-cssimgs-url + "oney-serp-mobile.png") no-repeat center; background-size: 100%; margin-left: 5px; display: block;
    }

    .oney-infos { display: none; }
  }

}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .cpt-oney-block {

    // SERP
    .page-serp & {
      background-color: $orx-oney-green; height: 45px; justify-content: space-between;

      .oney-label {
        font-size: 22px; font-weight: $orx-font-weight-light; color: $orx-white; margin-top: -3px; margin-left: 20px; display: inline-flex; align-items: center;

        .oney-label-add {
          display: inline-flex; align-items: center;
          .stronger { font-size: 38px; font-weight: 600; margin: 0 8px; }
        }
      }

      .oney-logo {
        background: $orx-white url($orx-cssimgs-url + "oney-serp-tablette.png") no-repeat 0 6px; background-size: 249px; width: 50%; position: relative;

        &:before {
          @include position(absolute,0 null null -23px);
          @include orx-size(23px,43px);
          content:""; background: $orx-white; border-radius: 20px 0 0  20px; display: block;
        }
      }

      .oney-mention {
        @include position(absolute,50% 30px null null);
        @include orx-size(31px,10px);
        background: $orx-white url($orx-cssimgs-url + "oney-serp-simple.png") no-repeat center; background-size: 100%; transform: translateY(-50%); z-index: 2;
      }

      .oney-infos {
        @include orx-size(18px);
        background-color: $orx-oney-green; z-index: 2;

        .link { font-size: 11px; font-weight: $orx-font-weight-light; color: $orx-white; }
      }

    }

    // FICHE PRODUIT
    .page-package & {
      .oney-label { font-size: 18px; margin-top: -12px; }
      .oney-logo {
        @include orx-size(130px,28px);
      }
    }
  }
}

@media #{$orx-mobile-first-lg} {
  .cpt-oney-block {

    // SERP
    .page-serp & {
      height: 55px; margin: -10px 0 20px;

      .oney-label { margin-left: inherit; }

      .oney-logo {
        background-position: 0 11px;

        &:before {
          @include position(absolute,0 null null -27px);
          @include orx-size(27px, 53px);
          border-radius: 26px 0 0 26px;
        }
      }
    }

    // FICHE PRODUIT
    .page-package & {
      .oney-label { font-size: 16px; margin-top: -8px; }
      .oney-logo {
        @include orx-size(100px,22px);
      }
    }
  }
}

@media #{$orx-mobile-first-xl} {
  .cpt-oney-block {

    // SERP
    .page-serp & {
      .oney-label { margin-left: 11%; }
    }
  }
}