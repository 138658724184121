/**
 * POPOVER
 *
 * $type component
 */


/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-popover {
  @include position(fixed, 0 nul null 0);
  @include orx-size(100%);
  font-size: 14px; background-color: $orx-white; box-shadow: 0 0 2px $orx-gray-e2; padding: 20px; margin: 0; z-index: 9999;

  .title-mobile {
    background-color: #eff2f7; color: $orx-brand-primary; font-weight: bold; margin: -20px -20px 20px; padding: 13px 20px;

    span { float: right; cursor: pointer; }
  }
  .scrollbar { overflow-y: scroll; margin: 0 -20px; padding: 0 15px; height: calc(100% - 52px); }
  .default-block {
    padding: 0 0 10px;

    .item { background-color: #eff2f7; color: #666a6e; font-size: 12px; font-weight: bold; padding: 6px; margin-right: 5px; white-space: nowrap; cursor: pointer; }
  }
  .datepicker-block { }
  .flexibility-block {
    .d-flex { align-items: flex-end; flex-wrap: wrap; justify-content: center; }
    .label { font-weight: bold; color: $orx-brand-primary; margin-bottom: 10px; }
    .item {
      background-color: $orx-white; color: $orx-brand-primary; border: 1px solid $orx-gray-e2; font-size: 12px; font-weight: bold; padding: 6px 13px; margin: 0 5px 5px 0; white-space: nowrap; cursor: pointer;

      &.selected,
      &:hover { background-color: $orx-brand-primary; color: $orx-white; border: 1px solid $orx-brand-primary; }
    }
    .button-wrap { margin-left: 10px; }
    .elem-button-primary { font-size: 14px !important; font-weight: 700 !important; padding: 10px 60px !important; margin-top: 20px !important; border-radius: 0 !important; background-color: $orx-brand-secondary; color: $orx-white; border: 0 none !important; text-transform: uppercase; width: 100% !important; cursor: pointer; }
  }
}


/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .cpt-popover {
    @include position(absolute, 72px nul null 9px);
    @include orx-size(auto);
    margin: 0 -11px;

    .scrollbar { overflow-y: hidden; margin: 0; padding: 0; height: auto; }
    .flexibility-block {
      .d-flex { flex-wrap: nowrap; justify-content: flex-start; }
      .elem-button-primary { width: auto !important; padding: 10px 24px !important; margin-top: 0 !important; }
    }
  }
}

@media #{$orx-mobile-first-lg} {
  .cpt-popover {
    @include position(absolute, 45px nul null 10px);
  }
}

@media #{$orx-mobile-first-xl} {
  .cpt-popover {
    @include position(absolute, 80px nul null 10px);
  }
}
