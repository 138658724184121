/**
 * BOOKING CALENDAR
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-booking-calendar {
  background-color: white; padding: 0 10px; margin: -10px; position: relative;

  .placeholder-style {
    background-color: $orx-gray-e9; border: 1px solid transparent; border-left: 0 none; border-right: 0 none; margin: 0 -10px; padding: 15px; cursor: pointer;

    &:hover { background-color: $orx-brand-primary; color: white; font-weight: $orx-font-weight-bold; border: 1px solid white; border-left: 0 none; border-right: 0 none; }

  }
  .month-title {
    @include position(sticky, -10px null null null);
    background-color: $orx-brand-secondary; color: white; text-transform: uppercase; font-size: 14px; font-weight: $orx-font-weight-semi-bold; padding: 8px 10px; margin: 0 -10px; z-index: 102;

    .by-price {
      @include position(absolute, 50% 10px null null);
      font-size: 12px; text-transform: none;
      transform: translateY(-50%);
    }
  }
  .booking-calendar-item {
    padding: 10px 0; border-bottom: 1px solid $orx-gray-de; display: flex; position: relative; cursor: pointer;

    .bc-days-content {
      display: flex; align-items: center;

      .bc-departure-day,
      .bc-return-day {
        @include orx-size(45px);
        background-color: $orx-gray-e6; border: 1px solid $orx-gray-c9; border-radius: 3px; text-align: center;
      }
    }
    .day { font-size: 12px; margin: 3px 0 -3px; }
    .number { color: $orx-brand-secondary; font-size: 24px; font-weight: $orx-font-weight-bold; }
    .month { font-size: 10px; font-weight: $orx-font-weight-light; display: none; }
    .arrow-day { color: $orx-gray-af; font-size: 22px; margin: 0 10px; }
    .bc-price-content {
      width: 100%; margin: 0 5px 0 15px; position: relative; display: flex; justify-content: center; flex-direction: column; align-items: flex-end;

      .discount-price,
      .best-price { font-size: 14px; font-weight: $orx-font-weight-semi-bold; background-color: white; color: $orx-brand-primary; border: 1px solid $orx-brand-primary; padding: 5px; margin-bottom: 4px; display: inline-block; }
      .bc-price {
        text-align: right; display: flex; justify-content: flex-end; align-items: flex-end;

        .old-price { color: $orx-gray-78; font-size: 16px; text-decoration: line-through; margin-right: 10px; }
        .real-price {
          font-weight: $orx-font-weight-bold; font-size: 24px;

          span { font-weight: $orx-font-weight-light; }
        }
      }
    }

    &.selected {
      background-color: $orx-gray-ffe; padding: 10px; margin: -1px -10px 0; border-top: 1px solid $orx-gray-de;

      .bc-days-content {
        .bc-departure-day,
        .bc-return-day { background-color: $orx-brand-primary; border: 1px solid $orx-brand-secondary; color: white; }
        .number { color: white; }
        .arrow-day { color: $orx-gray-6b; }
      }
      .bc-price-content {
        .discount-price,
        .best-price { color: white; background-color: $orx-brand-primary; }
        .bc-price { color: $orx-brand-primary; }
      }
    }
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-lg} {
  .cpt-booking-calendar {
    .booking-calendar-item {
      &:hover {
        background-color: $orx-gray-ffe; padding: 10px; margin: -1px -10px 0; border-top: 1px solid $orx-gray-de;

        .bc-days-content {
          .bc-departure-day,
          .bc-return-day { background-color: $orx-brand-primary; border: 1px solid $orx-brand-secondary; color: white; }
          .number { color: white; }
          .arrow-day { color: $orx-gray-6b; }
        }
        .bc-price-content {
          .discount-price,
          .best-price { color: white; background-color: $orx-brand-primary; }
          .bc-price { color: $orx-brand-primary; }
        }
      }
    }
  }
}
