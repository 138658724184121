/**
 * RESULT PRODUCT
 *
 * $type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-result-product {
  background-color: $orx-wrapper-full-gray; box-shadow: 0 0 5px rgba(black, 0.2); position: relative; margin-bottom: 27px;
  transition: all 0.2s ease-out;

  a { color: $orx-gray-3c; }
  .destination-product {
    background-color: white; width: 100%; padding: 15px; position: relative; display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;

    .elem-tag-top-sale {
      @include position(absolute, -15px 10px null null);
      background-color: $orx-brand-primary; color: white; font-size: 12px; height: 25px; font-weight: bold; text-transform: uppercase; padding: 0 8px; margin: 0; white-space: nowrap;
      display: flex; flex-direction: column; justify-content: center; align-items: center;
    }
    .hotel-name { font-size: 20px; font-weight: $orx-font-weight-semi-bold; width: 100%; order: 2; }
    .destination-name {
      font-size: 12px; font-weight: $orx-font-weight-regular; text-transform: none; order: 1; text-align: left; padding: 0 15px 0 0; margin-bottom: 3px; white-space: nowrap; position: relative;

      &:before {
        @include orx-size(2px, 110%);
        @include position(absolute, 50% null null 0);
        background-color: $orx-wrapper-full-gray; content: ""; display: none;
        transform: translateY(-50%);
      }
      strong {
        font-size: 12px; font-weight: $orx-font-weight-regular; padding-left: 15px; display: inline-block; position: relative;

        &:before {
          @include position(absolute, 50% null null 3px);
          content: "-";
          transform: translateY(-50%);
        }
      }
    }
  }
  .result-product-wrap {
    display: flex; flex-direction: column;

    .image-product {
      @include orx-size(100%, 190px); position: relative;

      .elem-image { height: 190px; }

      .video-icon-wrap {
        background-color:$orx-brand-primary; position: absolute; bottom: -1px; left: -1px; width: 39px; height: 29px; border: 1px solid #fff; display: flex; justify-content: center; align-content: center;

        .elem-ff-icon { font-size: 22px; color: $orx-white; }
      }

      .ref { background-color: $orx-white; font-size: 14px; color: $orx-gray-3c; border: 1px solid #ddd; padding: 0 5px; }

      .flash-sale {
        @include position(absolute, 5px null null 5px);
        @include orx-size(150px, 28px);
        display: flex; align-items: center; background-color: $orx-brand-primary; border-radius: 7px; z-index: 1; padding-inline-start: 7px;

        .flash-sale-image { color: $orx-white; }
        .flash-sale-title { font-size: 14px; color: $orx-white; font-weight: 500; margin-inline-start: 5px; }

        .added-values-tooltip {
          @include position(absolute, 33px null null 0);
          width: 225px; padding: 5px 3px 3px; background-image: $orx-linear-gradient-flash-sales; border-radius: 7px;

          .added-values-title { margin-left: 8px; font-size: 15px; color: $orx-white; font-weight: 500; }
          .added-values-container {
            background-color: $orx-white; padding: 5px 5px 5px 8px; border-radius: 7px; margin-top: 5px;

            .added-values-item {
              display: flex; font-size: 11px; font-weight: 500; margin-bottom: 5px;

              .added-values-image { color: $orx-brand-primary; margin-right: 5px; }
            }

            .added-values-item:last-child { margin: 0; }
          }
        }
      }
    }

    .informations-product {
      @include orx-size(100%,auto);
      background-color: white; margin-top: 2px; padding: 20px; position: relative;

      .result-product-price {
        .saving-wrap { color: $orx-brand-primary;border: 2px solid $orx-brand-primary; padding: 5px;}
        .price-block {
          justify-content: flex-end; margin-left: auto;
          .real-price {
            color: $orx-brand-primary; font-size: 26px; font-weight: $orx-font-weight-bold;

            .devise { font-weight: $orx-font-weight-light; }
          }
          .by { font-size: 12px; line-height: 10px; margin-left: 5px; position: relative; top: 4px; }
        }
        .brochure-price{font-size: 12px; justify-content: flex-end;}
        .next-departure-price { font-size: 13px; margin-top: 10px; margin-bottom: 12px; }
      }
      .cpt-promote-pdt {
        @include position(absolute, 20px null null 20px);
      }
      .description-pdt {
        margin: 10px 0 20px;

        .title { font-size: 16px; font-weight: $orx-font-weight-bold; margin-bottom: 10px; }
        .list-style {
          margin-left: 20px; font-size: 14px; line-height: 21px;

          .list-style-item {
            color: $orx-gray-80; position: relative;

            &:before {
              @include position(absolute, -6px null null -12px);
              content: "."; font-size: 24px; color: $orx-gray-80; display: block;
            }
          }
        }
      }

      .details-pdt {
        text-align: center; margin: 0px -10px 0;

        .d-flex { align-items: flex-start; justify-content: space-evenly; }
        .sep { font-size: 34px; color: $orx-gray-e4; }
        .details-pdt-item {
          width: 100%; padding: 0;

        .icon-wrap {
            color: $orx-brand-primary; font-size: 22px; margin-bottom: 5px;
            .elem-image { width: 22px; margin: 0 auto; }
          }
          .details-label { font-size: 12px; }
        }
      }

    }
  }
  &:hover { box-shadow: 0 0 5px rgba(black, 0.4); }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .cpt-result-product {

    .destination-product {
      flex-direction: row; justify-content: space-between; align-items: center;

      .elem-tag-top-sale {
        @include position(relative, auto auto null null);
        margin: 0 15px 0 -30px;
      }
      .hotel-name { order: 1; }
      .destination-name {
        font-size: 14px; text-align: center; text-transform: uppercase; padding: 0 15px 0 30px; margin-bottom: 0; order: 2;

        &:before { display: block; }
        strong {
          font-size: 16px; font-weight: $orx-font-weight-bold; display: block;

          &:before { display: none; }
        }
      }
    }
    .result-product-wrap {
      flex-direction: row;

      .image-product {
        @include orx-size(calc(100% - 340px), 356px);
        position: relative;

        .elem-image { height: 357px; }
        .video-button { position: absolute; bottom: 0; text-transform: uppercase; background-color:$orx-brand-primary; color: $orx-white;}
        .ref { background-color: $orx-white; font-size: 14px; color: $orx-gray-3c; border: 1px solid #ddd; padding: 0 5px; position: absolute; bottom: 0; right: 0; }
      }
      .informations-product {
        @include orx-size(340px,354px);
      }
    }
  }
}
