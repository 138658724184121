#ESChatEmbedded {
  &.escw-closed {
    display: none !important;
  }
  &.escw-opened {
    max-width: 350px !important;
    height: 50% !important;
    left: unset !important;
    top: unset !important;
  }
}

#ESChatCloseButton {
  &.escw-CloseButton {
    display: block !important; pointer-events: auto !important;
  }
}

@media only screen and (max-device-width: 768px) {
  #ESChatEmbedded {
    &.escw-opened {
      max-width: 310px !important;
      max-height: 380px !important;
      left: 15px !important;
      bottom: 20px !important;
    }
  }

  #ESChatOpenButton {
    right: 25px !important;
  }
}
