/**
 * BOOKING TRAVELERS
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-booking-travelers {
  background-color: white; margin: -10px; padding: 10px; position: relative;

  .booking-travelers-content { padding: 10px; }
  .booking-travelers-item { margin-bottom: 15px; position: relative; }
  .spinner-content {
    display: flex;

    .bt-label {
      font-size: 16px; width: 50%;

      .legend { font-size: 12px; font-weight: $orx-font-weight-light; margin-top: 4px; display: block; }
    }
    .spinner-block {
      display: inline-flex; justify-content: flex-end; width: 50%; text-align: right;

      .spinner-button {
        @include orx-size(40px);
        background-color: $orx-gray-f1; border: 1px solid $orx-gray-df; display: inline-flex; align-items: center; justify-content: center; cursor: pointer;
      }
      .spinner-remove {}
      .spinner-add {

      }
      .icon-wrap {
        @include orx-size(20px);
        font-size: 16px; color: $orx-brand-primary; border: 1px solid $orx-brand-primary; border-radius: 25px; display: flex; align-items: center; justify-content: center;
      }
      .spinner-value {
        @include orx-size(53px, 40px);
        background-color: white; text-align: center; font-weight: $orx-font-weight-bold; font-size: 16px; border: 0 none; border-top: 1px solid $orx-gray-df; border-bottom: 1px solid $orx-gray-df;
      }
    }
  }
  .age-children-selection {
    border-top: 1px solid $orx-gray-df; margin-top: 20px; padding-top: 20px; position: relative;

    .children-item {
      background-color: $orx-gray-f1; padding: 10px; margin-bottom: 15px;

      .row {
        margin: 0 -10px;

        .col-4 { padding: 0 10px; }
      }
      .label { font-size: 16px; font-weight: $orx-font-weight-light; text-transform: none; margin-bottom: 7px; color: $orx-gray-3c !important; }
      .select-wrap {
        position: relative;

        .input-style {
          @include orx-size(100%,40px);
          background-color: white; color: $orx-gray-3c; border: 1px solid $orx-gray-df; padding: 0 5px;
        }
      }
    }
  }
  .btn-wrap {
    @include position(fixed, null null 0 0);
    @include orx-size(100%,auto);
    background-color: $orx-gray-3c; padding: 10px; text-align: center; z-index: 200;
  }
  .cpt-error-message{color: red; font-size: 13px; margin-top: 5px;}
  [class*=elem-button--].disabled {background-color: #504e50; opacity: .5; pointer-events: none;}
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-lg} {
  .cpt-booking-travelers {
    .btn-wrap {
      @include position(relative, null null null null);
      @include orx-size(100%,auto);
      background-color: white; padding: 10px; text-align: center; z-index: 200;
    }
  }
}
