/**
 * RESULT PRODUCT
 *
 * $type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-results-sort {
  width: 100%; position: relative; display: flex; align-items: center;

  .results-sort-label {
    background-color: $orx-gray-e9; font-size: 16px; font-weight: $orx-font-weight-light; width: 100%; text-align: center; padding: 15px 10px; margin-right: 0; margin-bottom: 0;

    strong { font-size: 18px; font-weight: $orx-font-weight-bold; }
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-lg} {
  .cpt-results-sort {
    width: 80%; align-items: flex-end; justify-content: center; flex-direction: column; margin-left: auto;

    .results-sort-label { background-color: transparent; width: auto; text-align: right; padding: 0; margin-left: 15px; margin-bottom: 10px; }
  }
}
