/**
 * TRUST YOU
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-trust-you {

  // RATES
  .rating-score { background: #92c140; font-weight: $orx-font-weight-semi-bold; color: $orx-white; padding: 4px 6px; border-radius: 4px; }
  .rating-label,
  .rating-review-number { color: $orx-gray-80; }

  // IFRAME
  &.ty-iframe {
    iframe { border: 1px solid $orx-gray-f1; height: 455px; }
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .cpt-trust-you {
    // IFRAME
    &.ty-iframe {
      iframe { height: 490px; }
    }
  }
}

@media #{$orx-mobile-first-lg} {
  .cpt-trust-you {
    // IFRAME
    &.ty-iframe {
      iframe { min-height: 1300px; }
    }
  }
}
