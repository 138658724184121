/**
 * INCLUDE PRICE
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-include-price {
  background-color: $orx-wrapper-full-gray; padding: 20px; margin-bottom: 30px; position: relative;

  .title { font-weight: $orx-font-weight-bold; font-size: 18px; }
  .txt { padding-left: 10px; line-height: 22px; }
}

.iframe-block { margin-bottom: 30px; }