/**
 * PROMOTE PDT
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-promote-pdt {
  margin-bottom: 15px; display: inline-flex; position: relative;

  .d-flex { align-items: center; }
  .veryflexy-wrap { width: 140px; }
  .link-wrap {
    margin-left: 15px;

    .link-style { font-size: 12px; text-decoration: underline; cursor: pointer; }
  }
  .saving-wrap {
    color: $orx-brand-primary;
    border: 2px solid $orx-brand-primary;
    padding: 5px;
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-lg} {
  .cpt-promote-pdt {
    &.active {
      z-index: 2000;

      .link-wrap {
        .link-style { color: white; }
      }
    }
  }
}
