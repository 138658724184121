/**
 * DESTINATION INFO
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.cpt-destination-info {
  margin: 15px 0 0 15px;
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .cpt-destination-info { }
}
