/**
 * SEARCH ENGINE
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */
@import "reactDatepicker";
@import "popover";
.cpt-search-engine {
  @include position(fixed, 55px null null 0);
  @include orx-size(100%,calc(100% - 40px));
  background-color: $orx-gray-3c; color: white; padding: 0 20px 70px; margin: 40px 0 0; overflow-y: auto; z-index: 100;

  .mobile-app & { top: 0; }
  .h2-style { background-color: $orx-gray-31; font-size: 20px; text-transform: uppercase; padding: 15px 20px; margin: 0 -20px 15px; }
  .booking-overlay {
    @include position(fixed, 0 null null 0);
    @include orx-size(100%);
    background-color: rgba(black, 0.8); z-index: 100;
  }
  .search-panel-content {
    .search-panel-item {
      margin-bottom: 20px;

      .label-select { text-transform: none; font-size: 14px; color: $orx-gray-cf; margin-bottom: 8px; transition: all 1s ease-out; }
      .elem-custom-select {
        border: 1px solid transparent;
        transition: all 0.5s ease-out;

        &.dark-style { border: 1px solid $orx-gray-08; }
      }

      &.active {
        position: relative; z-index: 101;

        .label-select { color: white; }
        .elem-custom-select { border-color: $orx-brand-primary; }
      }
    }
  }
  .button-wrap {
    @include position(fixed, null null 0 0);
    width: 100%; background-color: $orx-gray-31; padding: 15px 20px; margin: 0;

    .elem-button {
      @include orx-size(100%,40px);
      display: flex; justify-content: center; align-items: center;
    }
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-lg} {
  .cpt-search-engine {
    @include position(relative, auto null null auto);
    @include orx-size(auto);
    padding: 0 20px; margin: 30px 0; overflow: inherit; z-index: auto;

    .button-wrap {
      @include position(relative, null null auto auto);
      width: auto; margin: 0 -20px 0;
    }
  }
}
